import React from 'react';
import '../css/contact.scss';
import { graphql } from 'gatsby';
import Markdown from '../components/Markdown';
import Layout from '../components/Layout';
import HeadingDecorated from '../components/HeadingDecorated';
import Seo from '../components/Seo';
import mailImg from '../images/mail.svg';
import downloadImg from '../images/download.svg';

export const query = graphql`
  {
    contact: allStrapiContact {
      nodes {
        addressSegment1 {
          data {
            addressSegment1
          }
        }
        addressSegment2 {
          data {
            addressSegment2
          }
        }
        email
        files {
          caption
          localFile {
            url
          }
        }
      }
    }

    downloads: allStrapiContactFile(
      filter: { locale: { eq: "pl" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        name
        file {
          localFile {
            url
          }
        }
      }
    }
  }
`;

export const LOCALIZED_PAGES = { pl: '/kontakt/', en: '/contact/' };

const ContactPage = ({
  data: {
    contact: {
      nodes: [data],
    },
    downloads: { nodes: files },
  },
}) => {
  return (
    <Layout localizedPages={LOCALIZED_PAGES}>
      <Seo title="Kontakt" />
      <div className="contact">
        <section className="contact__section">
          <HeadingDecorated className="contact__heading">
            Kontakt
          </HeadingDecorated>
          <h3>Dane adresowe</h3>
          <div className="contact__address">
            <Markdown>{data.addressSegment1.data.addressSegment1}</Markdown>
            <Markdown>{data.addressSegment2.data.addressSegment2}</Markdown>
          </div>
          <a href={`mailto:${data.email}`} className="contact__email">
            <img src={mailImg} alt="e-mail" />
            {data.email}
          </a>
        </section>
        <section>
          <HeadingDecorated className="contact__heading">
            Materiały do pobrania
          </HeadingDecorated>
          <h3>Dokumenty do pobrania</h3>
          <div className="contact__downloads">
            {files.map((file) => (
              <div
                className="contact__download-item"
                key={file.file.localFile.url}
              >
                <p>{file.name}</p>
                <a
                  href={file.file.localFile.url}
                  className="contact__download-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={downloadImg} alt="pobierz" />
                  Pobierz
                </a>
              </div>
            ))}
          </div>

          <p className="contact__authors">
            Projekt identyfikacji wizualnej Instytutu: Zofia Lasocka
          </p>
          <p className="contact__authors">
            Wdrożenie projektu strony internetowej: Sebastian Koszuta
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default ContactPage;
